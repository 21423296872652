import React from "react";
import "./Footer.css";
import { HashLink } from "react-router-hash-link";

export default function Footer({ space }) {
  return (
    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-1 ${
            space ? "pt-150" : "pt-60"
          }`}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src="assets/logos-web/justk8s-logo.webp"
                    alt="footer logo"
                    width="220"
                    className="img-fluid"
                  />
                  <p className="footer-text">
                    Holisticly empower Cloud Native apps without value-added
                    ideas. Seamlessly evolve cross-platform experiences.
                  </p>

                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a
                          href="https://www.linkedin.com/company/justk8s/"
                          target="_blank"
                          title="JustK8s"
                        >
                          <span className="ti-linkedin footer-text"></span>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href="https://github.com/justk8s"
                          target="_blank"
                          title="Twitter"
                        >
                          <span className="ti-github footer-text"></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Solutions</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a className="footer-text" href="#/">
                            Help
                          </a>
                        </li>
                        <li className="mb-2">
                          <a className="footer-text" href="#/">
                            Events
                          </a>
                        </li>
                        <li className="mb-2">
                          <a className="footer-text" href="#/">
                            Live Support
                          </a>
                        </li>
                        <li className="mb-2">
                          <a className="footer-text" href="#/">
                            Open Sources
                          </a>
                        </li>
                        <li className="mb-2">
                          <a className="footer-text" href="#/">
                            Documentation
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Company</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <HashLink className="footer-text" smooth to="#">
                            Home
                          </HashLink>
                        </li>
                        <li className="mb-2">
                          <HashLink className="footer-text" smooth to="#about">
                            About
                          </HashLink>
                        </li>
                        <li className="mb-2">
                          <HashLink
                            className="footer-text"
                            smooth
                            to="#products"
                          >
                            Product
                          </HashLink>
                        </li>
                        <li className="mb-2">
                          <HashLink
                            className="footer-text"
                            smooth
                            to="#contact"
                          >
                            Contact
                          </HashLink>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">Location</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          <span className="footer-text">
                            <span className="ti-location-pin mr-2"></span>
                            Street of Tunis km6, Building Ennakhla
                            <br />
                            Sfax, Tunisia 3021
                          </span>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-mobile mr-2"></span>
                          <a className="footer-text" href="tel:+21670038285">
                            +216 70 038 285
                          </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-email mr-2"></span>
                          <a
                            className="footer-text"
                            href="mailto:info@justk8s.com"
                          >
                            info@justk8s.com
                          </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-world mr-2"></span>
                          <a
                            className="footer-text"
                            href="http://www.justk8s.com/"
                          >
                            www.justk8s.com
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      © 2024 Just K8s, All Rights Reserved
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
