import React from "react";
import "./AboutApp.css";

export default function AboutApp() {
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-lg-5">
              <span className="text-uppercase color-secondary sub-title">
                About Us
              </span>
              <h2>Empower your cloud native journey</h2>
              <p className="mb-4 lh-190 about-font-size">
                Our mission at Just K8s is to empower organizations in cloud
                native and Kubernetes space, by overseeing the underlying
                infrastructure focusing on reliability, elasticity, and
                resiliency.
              </p>
              <ul className="list-unstyled">
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0 about-font-size">
                        Offers a Base product for the management of a fleet
                        Kubernetes clusters.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0 about-font-size">
                        Helps organizations with Cloud Native Evolution.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0 about-font-size">
                        Provides Operations, Automation, and Reliability
                        Engineering on top of Kubernetes.
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0 about-font-size">
                        Day 2 Operations: Guaranteed with 99.95% SLA
                      </p>
                    </div>
                  </div>
                </li>
                <li className="py-2">
                  <div className="d-flex align-items-center">
                    <div>
                      <div className="badge badge-circle badge-primary mr-3">
                        <span className="ti-check"></span>
                      </div>
                    </div>
                    <div>
                      <p className="mb-0 about-font-size">
                        Development and Operations of Cloud Native apps.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="about-content-right img-about-container">
                <img
                  src="assets/logos-web/our-images/image7.webp"
                  alt="about us"
                  className="img-about"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
