import Footer from "../../components/layout/footer/Footer";
import AboutApp from "../../components/about/AboutApp";
import AccordionWithImg from "../../components/Faqs/AccordionWithImg";
import Contact from "../../components/contact/Contact";
import FeatureImg from "../../components/features/FeatureImg";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import PromoTwo from "../../components/promo/PromoTwo";
import Hero from "./Hero";

export default function Home() {
  return (
    <Layout>
      <Navbar darkBg />
      <Hero />
      <AboutApp />
      <FeatureImg ImgSource="assets/logos-web/our-images/image6.webp" />
      <AccordionWithImg />
      <PromoTwo />
      <Contact bgColor="gray-light-bg" />
      <Footer />
    </Layout>
  );
}
