import React from "react";

export default function PromoTwo() {
  return (
    <>
      <section className="promo-section mt-5 ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-10">
              <div className="section-heading mb-5">
                <span className="text-uppercase color-secondary sub-title">
                  Key features
                </span>
                <h2 className="mb-6">
                 We will help you to Build innovative solutions.
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-sm-6 mb-lg-0">
              <div className="card single-promo-card single-promo-hover">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-credit-card icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Kubernetes as service</h5>
                    <p className="text-muted mb-0">
                      Extremely easy to provision clusters.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-control-record icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Multi-cloud environments</h5>
                    <p className="text-muted mb-0">
                      Quick to integrate for most cloud vendors.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-vector icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Available as SaaS and Installable</h5>
                    <p className="text-muted mb-0">
                      Remain consistent while growing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="card single-promo-card single-promo-hover mb-lg-0">
                <div className="card-body">
                  <div className="pb-2">
                    <span className="ti-receipt icon-md color-secondary"></span>
                  </div>
                  <div className="pt-2 pb-3">
                    <h5>Certified Kubernetes Conformant</h5>
                    <p className="text-muted mb-0">
                      100% Vanilla Kubernetes with flexible configuration
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
