import React from "react";
import Counter from "../../components/counter/Counter";
import "./Hero.css";

export default function Hero() {
  return (
    <>
      <section
        className="hero-section pt-100 background-img"
        style={{
          background:
            "url('assets/img/hero-bg.webp')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-5 col-lg-5">
              <div className="hero-content-left text-white mt-5">
                <span className="text-uppercase h6">
                  The Evolution of Cloud Native
                </span>
                <h1 className="text-white">
                  <span>Fully Operational</span> Kubernetes clusters anywhere
                </h1>
                <p className="lead">
                  Managed Kubernetes service in a multi-cloud and multi-cluster
                  fashion, that manages the lifecycle of Production-grade
                  Kubernetes infrastructure.
                </p>

                <a href="#products" className="btn app-store-btn">
                  Our Product
                </a>
              </div>
            </div>
            <div className="col-md-7 col-lg-7">
              <div className="hero-animation-img hero-img-container">
                <img
                  src="assets/logos-web/our-images/hero.webp"
                  alt="img"
                  className="img-fluid hero-img"
                />
              </div>
            </div>
          </div>
          <Counter />
        </div>
      </section>
    </>
  );
}
